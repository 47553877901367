<template>
	<div>
		<div class="flex header">
			<h2>抄单记录</h2>
			<el-button @click="toAddCopyRecord">新增抄单记录</el-button>
		</div>
		<div class="table">
			<el-table
					:data="tableData"
					:header-cell-style="{'background':'rgba(0, 103, 166, 0.3)','color': '#ffffff','fontWeight':'bold','textAlign':'center'}"
					height="calc(100vh - 256px)"
					style="width: 100%">
				<el-table-column align="center" show-overflow-tooltip
												 label="抄单日期">
					<template slot-scope="scope">
						{{ scope.row.copyDate|formatTimer }}
					</template>
				</el-table-column>
				<el-table-column
						prop="carNo" align="center" show-overflow-tooltip
						label="车牌号码">
				</el-table-column>
				<el-table-column
						prop="carOwner" align="center" show-overflow-tooltip
						label="车主姓名">
				</el-table-column>
				<el-table-column
						prop="ownerTel" align="center" show-overflow-tooltip
						label="电话号码">
				</el-table-column>
				<el-table-column
						prop="memberType" align="center" show-overflow-tooltip
						label="用户类型">
				</el-table-column>
				<el-table-column
						prop="parkName" align="center" min-width="120" show-overflow-tooltip
						label="抄单停车场">
				</el-table-column>
				<el-table-column
						prop="copyContent" align="center" show-overflow-tooltip
						label="抄单内容">
				</el-table-column>
				<el-table-column align="center"
												 label="图片"
												 width="100">
					<template slot-scope="scope">
						<div class="imgName flex" style="height: 50px;justify-content: center" :key="index"
								 v-for="(item,index) in scope.row.imgList"
								 v-show="index<1">
							<el-image
									style="height: 80%" fit="cover"
									:src="item.imgPath"
									:preview-src-list="handleViewImg(scope.row.imgList)">
							</el-image>
						</div>
					</template>
				</el-table-column>
				<el-table-column align="center"
												 label="状态" show-overflow-tooltip
												 width="100">
					<template slot-scope="scope">
						<el-tag type="success" v-if="scope.row.sendState == 2">已发送</el-tag>
						<el-tag type="warning" v-else>未发送</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center"
												 label="是否违规停车" show-overflow-tooltip
												 width="110">
					<template slot-scope="scope">
						<el-tag type="danger" v-if="scope.row.isIllegalParking == 1">是</el-tag>
						<el-tag type="warning" v-else>否</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" fixed="right"
												 label="操作" width="180px">
					<template slot-scope="scope">
						<el-button class="update" @click="toDetail(scope.row)">详情</el-button>
						<el-button class="update" @click="handleSend(scope.row)">发送通知</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
					class="pagination"
					@current-change="handleCurrentChange"
					:current-page="page.current"
					:page-size="page.size"
					layout="prev, pager, next, total"
					:total="page.total">
			</el-pagination>
		</div>
		<!--		抄单记录-->
		<el-dialog
				top="0" class="commonDia updateMem" :close-on-click-modal="false"
				:visible.sync="copyRecord" :show-close="false"
				width="700px" :close-on-press-escape="false">
			<template #title>
				<h1 class="tit">新增抄单记录</h1>
			</template>
			<div class="flex formRecord">
				<div class="left">
					<label class="label">车牌查询：</label>
					<el-input v-model="carNo" placeholder="请搜索车牌号码"><i slot="suffix" class="el-input__icon el-icon-search"
																														 style="cursor: pointer;"
																														 @click="searchByCarNo"></i>
					</el-input>
					<label class="label">车辆信息：</label>
					<div class="comInfo">
						<p>用户类型：{{ carInfo.memberType }}</p>
						<p>有效时期：{{ carInfo.startDate | formatDay}}至{{ carInfo.endDate | formatDay}}</p>
						<p>车主姓名：{{ carInfo.carOwner }}</p>
						<p>电话号码：{{ carInfo.ownerTel }}</p>
						<p>公司名称：{{ carInfo.ownerCompany }}</p>
					</div>
				</div>
				<el-form label-position="top" ref="form" class="rowsInfo " label-suffix="：" label-width="80px"
								 :model="formRecord">
					<el-form-item label="图片上传">
						<el-upload multiple
											 class="upload-demo"
											 :action="uploadUrl"
											 name="uploadFile"
											 :headers="{token:headToken}"
											 :on-success="successUpload"
											 :file-list="formRecord.imgPath">
							<el-button class="uploadImg" type="primary"><i class="el-icon-plus avatar-uploader-icon"></i>图片上传
							</el-button>
						</el-upload>
					</el-form-item>
					<el-form-item label="车牌号码">
						<el-input v-model="formRecord.carNo" placeholder="请输入车牌号码"></el-input>
					</el-form-item>
					<el-form-item label="电话号码" prop="ownerTel">
						<el-input v-model="formRecord.ownerTel" placeholder="请输入电话号码"></el-input>
					</el-form-item>
					<el-form-item label="抄单停车场">
						<el-select v-model="formRecord.parkName" placeholder="请选择抄单停车场" value-key="id" @change="selParking">
							<el-option :label="item.parkingName" :key="ind" v-for="(item,ind) in parkingList"
												 :value="item"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="抄单内容">
						<el-input v-model="formRecord.copyContent" type="textarea" rows="6" placeholder="请输入抄单内容"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="copyRecord=false">取 消</el-button>
				<el-button type="primary" @click="doAddCopyRecord">确 定</el-button>
			</div>
		</el-dialog>
		<!--		发送通知-->
		<el-dialog
				top="0" class="commonDia updateMem" :close-on-click-modal="false"
				:visible.sync="sendInfo" :show-close="false"
				width="600px" :close-on-press-escape="false">
			<template #title>
				<h1 class="tit">发送通知</h1>
			</template>
			<el-form label-position="top" ref="form" class="rowsInfo" label-suffix="：" label-width="80px"
							 :model="formSend">
				<div class="flex">
					<el-form-item label="车牌号码">
						<el-input :value="formSend.carNo" readonly></el-input>
					</el-form-item>
					<el-form-item label="电话号码">
						<el-input :value="formSend.ownerTel" readonly></el-input>
					</el-form-item>
				</div>
				<div class="flex">
					<el-form-item label="违规操作">
						<el-input :value="formSend.copyContent" readonly></el-input>
					</el-form-item>
					<el-form-item label="违规停车场">
						<el-input :value="formSend.parkName" readonly></el-input>
					</el-form-item>
				</div>
				<el-form-item label="备注">
					<el-input v-model="formSend.sendRemark" type="textarea" rows="6" placeholder="请输入备注"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="sendInfo=false">取 消</el-button>
				<el-button type="primary" @click="send('form')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { getCopyRecordList, getMemberInfoByCarNo, getCarParkInfoList, addCopyOrderRecord } from "@/api/api";

export default {
	name: "copyingRecords",
	data() {
		return {
			tableData: [],
			page: {
				current: 1,
				size: 10,
				total: 0
			},
			copyRecord: false,
			carNo: "",
			formRecord: {
				imgPath: [],
				parkName: "",
				parkId: "",
				copyContent: "",
				ownerTel: "",
				carNo: ""
			},
			carInfo: {
				memberType: "",
				memberId: "",
				startDate: "",
				endDate: "",
				carOwner: "",
				ownerTel: "",
				ownerCompany: ""
			},
			parkingList: [],
			imgList: [],
			uploadUrl: this.$local.baseURL() + "/car/copy-order-record/uploadImg",
			headToken: localStorage.accessToken,
			sendInfo: false,
			formSend: {
				id: "",
				carNo: "",
				ownerTel: "",
				copyContent: "",
				parkName: ""
			},
			srcList: [
				"https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
				"https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg"
			],
			baseUrl: this.$local.baseURL()
		};
	},
	mounted() {
		this.getData();
		this.getParkingList();
	},
	methods: {
		toAddCopyRecord() {
			this.formRecord = {
				imgPath: [],
				parkName: "",
				parkId: "",
				copyContent: "",
				ownerTel: "",
				carNo: ""
			};
			this.copyRecord = true;
		},
		handleViewImg(list) {
			let srcList = [];
			list.forEach(i => {
				srcList.push(i.imgPath);
			});
			return srcList;
		},
		getData() {
			let params = {
				page: this.page.current,
				size: this.page.size
			};
			getCopyRecordList(JSON.stringify(params)).then(res => {
				this.tableData = res.data.rows;
				this.page.total = res.data.total;
			}).catch(err => {
				console.log("err: " + err);
			});
		},
		handleCurrentChange(val) {
			this.page.current = val;
			this.getData();
		},
		// 搜索
		searchByCarNo() {
			let params = {
				carNo: this.carNo
			};
			getMemberInfoByCarNo(JSON.stringify(params)).then(res => {
				/*this.carInfo = {
					memberType: res.data.data.memberType,
					memberId: res.data.data.memberId,
					startDate: res.data.data.startDate,
					endDate: res.data.data.endDate,
					carOwner: res.data.data.carOwner,
					ownerTel: res.data.data.ownerTel,
					ownerCompany: res.data.data.ownerCompany
				};*/
                this.carInfo = res.data.data
				this.formRecord.ownerTel = res.data.data.ownerTel;
				this.formRecord.carNo = this.carNo;
			}).catch(err => {
				console.log("err: " + err);
			});
		},
		getParkingList() {
			let params = {
				page: this.page.current,
				size: 9999
			};
			getCarParkInfoList(JSON.stringify(params)).then(res => {
				this.parkingList = res.data.rows;
			});
		},
		successUpload(response, file) {
			if (response.code == 200) {
				let obj = {
					imgName: file.name,
					imgPath: response.data.filePath
				};
				this.imgList.push(obj);
			}
		},
		// 抄单停车场
		selParking(val) {
			this.formRecord.parkId = val.id;
			this.formRecord.parkName = val.parkingName;
		},
		// 新增抄单记录
		doAddCopyRecord() {
			if (this.carInfo.carNo == "") {
				this.$message.error("请填写车牌号");
				return;
			}
			if (this.formRecord.parkId == "") {
				this.$message.error("请选择停车场");
				return;
			}
			if (this.formRecord.copyContent == "") {
				this.$message.error("抄单内容不能为空");
				return;
			}
			let params = {
				carNo: this.formRecord.carNo,
				ownerTel: this.formRecord.ownerTel,
				copyContent: this.formRecord.copyContent,
				parkName: this.formRecord.parkName,
				parkId: this.formRecord.parkId,
				memberType: this.carInfo.memberType,
				carOwner: this.carInfo.carOwner,
				memberId: this.carInfo.memberId,
				imgList: this.imgList
			};

			addCopyOrderRecord(params).then(res => {
				//添加成功
				if (res.data.code === 200) {
					this.copyRecord = false;
					this.getData();
					this.$message.success(res.data.msg);
				} else {
					this.$message.error(res.data.msg ? res.data.msg : "操作失败");

				}
			});
		},
		// 发送通知
		handleSend(row) {
			this.sendInfo = true;
			this.formSend = {
				id: row.id,
				carNo: row.carNo,
				ownerTel: row.ownerTel,
				copyContent: row.copyContent,
				parkName: row.parkName,
				sendRemark: ""
			};
		},
		toDetail(row) {
			console.log(row);
			this.$router.push({path:'/index/copyingDetail',query:{carNo:row.carNo}})
		},
		send() {
			if (this.formSend.ownerTel == "") {
				this.$message.error("请填写手机号");
			}
			if (this.formSend.sendRemark == "") {
				this.$message.error("备注必填");
			}
			this.$get("/car/copy-order-record/sendNote?id=" + this.formSend.id + "&ownerTel=" + this.formSend.ownerTel + "&sendRemark=" + this.formSend.sendRemark).then(res => {
				if (res.data.code === 200) {
					this.sendInfo = false;
					this.getData();
					this.$message.success(res.data.msg);
				} else {
					this.$message.error(res.data.msg ? res.data.msg : "操作失败");

				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/table";
</style>
